import React from "react"

export default function Start(props) {
  const { page, state, stateDispatch, SwitchPage, setOpenForm } = props
  return (
    <form onSubmit={e => SwitchPage(e, "brand")}>
      <div className={page === "start" ? "text-field show" : "text-field hide"}>
        <p>What's your name?</p>
        <input
          type="text"
          placeholder="John Doe"
          value={state.name}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "name",
            })
          }
          required
        />
      </div>
      <div className={page === "start" ? "text-field show" : "text-field hide"}>
        <p>What's your business email?</p>
        <input
          type="email"
          placeholder="john.doe@email.com"
          value={state.email}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.value,
              field: "email",
            })
          }
          required
        />
      </div>
      <div className={page === "start" ? "checkbox show" : "checkbox hide"}>
        <input
          id="newsletter"
          type="checkbox"
          checked={state.newsletter}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.checked,
              field: "newsletter",
            })
          }
        />
        <p>Click to get marketing goodies to your inbox</p>
        <label htmlFor="newsletter">
          <div className="box-text">
            I'll send you blog post, scripts, automation and free stuff directly
            to your inbox. <br />
            You can unsubscribe at any time and I'll have no time to spam you.
          </div>
        </label>
      </div>
      <div className={page === "start" ? "checkbox show" : "checkbox hide"}>
        <input
          id="consent"
          type="checkbox"
          checked={state.consent}
          onChange={e =>
            stateDispatch({
              type: "field",
              value: e.currentTarget.checked,
              field: "consent",
            })
          }
          required
        />
        <p>
          <span>
            Do you accept that I contact you and save your information? *
          </span>
        </p>
        <label htmlFor="consent">
          <div className="box-text">
            I need to save your information to deliver the report and contact
            you.
            <br />
            I'm using Google to process your information and Hubspot to save it.
          </div>
        </label>
      </div>
      <button
        className={
          page === "start" ? "submit-button show" : "submit-button hide"
        }
        type="submit"
      >
        Next
      </button>
      <button
        type="button"
        className={
          page === "start"
            ? "submit-button show back"
            : "submit-button hide back"
        }
        onClick={() => setOpenForm(false)}
      >
        Close window
      </button>
    </form>
  )
}
