import React, { useState, useReducer, useRef } from "react"

// Components
import Start from "./start"
import Brand from "./brand"
import Competitor from "./competitor"
import CompetitorList from "./competitor-list"
import Confirm from "./confirm"
import ThankYou from "./thank-you"

// Data
import { initialState } from "./data"
import {
  HeadlineText,
  StateReducer,
} from "./functions"

export default function BrandingForm({ openForm, setOpenForm, href }) {
  const [page, setPage] = useState("start")
  const [headline, setHeadline] = useState(HeadlineText(page))
  const [formAnimate, setFormAnimating] = useState(false)
  const [animating, setAnimating] = useState(false)
  const [state, stateDispatch] = useReducer(StateReducer, initialState)
  const scrollRef = useRef(null)

  const SwitchPage = (e, page, index) => {
    e.preventDefault()
    setFormAnimating(true)
    setTimeout(() => {
      setAnimating(true)
      setPage(false)
    }, 200)
    scrollRef.current.scrollTo({top: 0, behavior: 'smooth'})
    if (index >= 0)
      stateDispatch({
        type: "competitorIndex",
        value: index,
      })
    setTimeout(() => {
      setPage(page)
      setHeadline(HeadlineText(page))
      setAnimating(false)
      setFormAnimating(false)
    }, 500)
  }

  return (
    <div id="form-overlay" style={{background: "rgba(255, 255, 255, 0.466)"}} className={openForm ? "open" : "closed"}>
      <div id="branding" className="wrapper" ref={scrollRef}>
        <div id="form" className={formAnimate ? "hide" : "show"}>
          <h2 className={formAnimate ? "hide" : "show"}>{headline}</h2>
          {page === "start" || animating ? (
            <Start
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
              setOpenForm={setOpenForm}
            />
          ) : null}
          {/* BRAND */}
          {page === "brand" || animating ? (
            <Brand
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
            />
          ) : null}
          {/* COMPETITOR */}
          {page === "competitor" || animating ? (
            <Competitor
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
            />
          ) : null}
          {/* Competitor List */}
          {page === "competitor-list" || animating ? (
            <CompetitorList
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
            />
          ) : null}
          {/* Confirm Page */}
          {page === "confirm" || animating ? (
            <Confirm
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
              setOpenForm={setOpenForm}
              href={href}
            />
          ) : null}
          {/* Confirm Page */}
          {page === "thank-you" || animating ? (
            <ThankYou
              page={page}
              state={state}
              stateDispatch={stateDispatch}
              SwitchPage={SwitchPage}
              setOpenForm={setOpenForm}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}
