import React from 'react'

export default function Competitor(props) {
    const { page, state, stateDispatch, SwitchPage } = props;
    return (
        <form onSubmit={e => SwitchPage(e, "competitor-list")}>
          <div
            className={
              page === "competitor" ? "text-field show" : "text-field hide"
            }
          >
            <p>What's the name of your competitor?</p>
            <input
              type="text"
              placeholder="Yahoo"
              value={state.competitors[state.competitorIndex].competitorName}
              onChange={e =>
                stateDispatch({
                  type: "competitor",
                  index: state.competitorIndex,
                  value: e.currentTarget.value,
                  field: "competitorName",
                })
              }
              required
            />
          </div>
          <div
            className={
              page === "competitor" ? "text-field show" : "text-field hide"
            }
          >
            <p>What's your competitors website URL?</p>
            <input
              type="url"
              placeholder="www.yahoo.com"
              value={state.competitors[state.competitorIndex].competitorUrl}
              onChange={e =>
                stateDispatch({
                  type: "competitor",
                  index: state.competitorIndex,
                  value: e.currentTarget.value,
                  field: "competitorUrl",
                })
              }
              required
            />
            <p className="require">You must include http:// or https://</p>
          </div>
          <div
            className={
              page === "competitor" ? "text-field multi-line show" : "text-field multi-line hide"
            }
          >
            <p>How do you search on Google for your competitor?</p>
            <input
              type="text"
              placeholder="yahoo"
              value={
                state.competitors[state.competitorIndex].competitorSearchTerm
              }
              onChange={e =>
                stateDispatch({
                  type: "competitor",
                  index: state.competitorIndex,
                  value: e.currentTarget.value,
                  field: "competitorSearchTerm",
                })
              }
              required
            />
          </div>
          <button
            className={
              page === "competitor"
                ? "submit-button show"
                : "submit-button hide"
            }
            type="submit"
          >
            Add competitor
          </button>
          <button
            type="button"
            className={
              page === "competitor"
                ? "submit-button show back"
                : "submit-button hide back"
            }
            onClick={e => SwitchPage(e, "competitor-list")}
          >
            Back
          </button>
        </form>
    )
}
