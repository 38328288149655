import React from 'react'

import ContactForm from "../../../fetch/contactForm"
import Image from "../../image/image"
import ConnectionFailed from "../connection-failed"

export default function Confirm(props) {
    const { page, state, SwitchPage, setOpenForm, href } = props;
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)

    const submitForm = (e) => {
      e.preventDefault()
      setLoading(true)
      const object = state;
      object.page = href
      object.pageName = "Branding Form"
      ContactForm(object).then(() => {
        SwitchPage(e, "thank-you")
        setLoading(false)
      }).catch(() => {
        setLoading(false)
        setError(true)
      })
    }

    return (
      <>
        <div className={page === "confirm" ? "confirm show" : "confirm hide"}>
        <div className={page === "confirm" ? "text show" : "text hide"}>
          <b>Overview</b>
          <br />
          The brand and competitor report will compare your brand —{" "}
          <span>{state.brandName}</span> — with the competitors added to this
          order based on the data for the <span>{state.brandMarket}</span>{" "}
          market.
          <br />
          <br />
          <b>Your brand</b>
          <br />
          <span>{state.brandName}</span> with the website{" "}
          <span>{state.brandUrl}</span> and the search term of{" "}
          <span>"{state.brandSearchTerm}"</span>.
          <br />
          <br />
          <b>Competitors</b>
          {state.competitors.map(obj => {
            if (obj.competitorName) {
              return (
                <React.Fragment key="">
                  <br />
                  <span>{obj.competitorName}</span> with the website{" "}
                  <span>{obj.competitorUrl}</span> and the search term of{" "}
                  <span>"{obj.competitorSearchTerm}"</span>.
                </React.Fragment>
              )
            }
          })}
          <br />
          <br />
          <b>Delivery</b>
          <br />
          The Branding and competitor report will be delivered as fast as
          possible depending on how many reports I do have in the pipeline.
          I'll contact — <span>{state.email}</span> — by email whenever the
          report is created and can be presented.
          <br />
          <br />
          <b>Price</b>
          <br />
          The branding and competitor report is <span>100% free</span> and
          I'll not charge you anything for it.
          <br />
          <br />
          <b>Copyright</b>
          <br />
          By clicking the "Confirm - Order report" button you agree that the
          report is under copyright law and you, and/or your organization, is not
          allowed to share the report with any external partner, external
          company or external person outside of your organization that is an,
          direct or in-direct, competitor of my (Johannes Eklund) services.
          <br />
          <br />
          <b>
            If the copyright clause is surpassed I'll charge you{" "}
            <span>2500$</span> (two thousand five hundred dollars) for the
            report — So please don't share it externally.
          </b>
        </div>
        <button
          type="button"
          id={loading ? "loading" : ""}
          className={
            page === "confirm" ? "submit-button show" : "submit-button hide"
          }
          onClick={e => submitForm(e, "thank-you")}
        >
          {loading ? <div className="loading-icon"><Image src="loading.svg" /></div> : "Send request"}
        </button>
        <button
          type="button"
          className={
            page === "confirm"
              ? "submit-button show back"
              : "submit-button hide back"
          }
          onClick={e => SwitchPage(e, "competitor-list")}
        >
          Back
        </button>
      </div>
      <ConnectionFailed error={error} setOpenForm={setOpenForm} />
      </>
    )
}
