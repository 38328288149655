export const HeadlineText = type => {
  switch (type) {
    case "start":
      return "Let's order you a Brand & Competitor report"
    case "brand":
      return "Tell me more about your brand"
    case "competitor":
      return "Let's add information about a competitor"
    case "competitor-list":
      return "Competitors to compare against"
    case "confirm":
      return "Information"
    case "thank-you":
      return "Thank you"
  }
}

export const StateReducer = (state, action) => {
  switch (action.type) {
    case "field": {
      state[action.field] = action.value
      return { ...state }
    }
    case "competitor": {
      state.competitors[action.index][action.field] = action.value
      return { ...state }
    }
    case "competitorIndex": {
      state.competitorIndex = action.value
      return { ...state }
    }
    case "clearCompetitor": {
      state.competitors[action.index] = {
        competitorName: "",
        competitorUrl: "",
        competitorSearchTerm: "",
      }
      return { ...state }
    }
    default:
      return { ...state }
  }
}
