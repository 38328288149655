import React, { useState } from "react"

// Components
import BrandingForm from "../components/forms/branding-form/branding-form"
import Image from "../components/image/image"

// SEO
import SEO from "../seo/main"

export default function BrandingReport() {
  const [openForm, setOpenForm] = useState(false)

  const toggleForm = () => {
    setTimeout(() => {
      setOpenForm(!openForm)
    }, 0)
  }

  return (
    <>
      <SEO
        title={
          "Report: How are your brand performing compared to your competitors?"
        }
        description={
          "Order the report where you get actionable insights on how your brand is performing organically and technically compared to your competitors and industry."
        }
      />
      <BrandingForm openForm={openForm} setOpenForm={setOpenForm} href={"https://www.johanneseklund.com/branding-report/"} />
      <div id="branding-report">
        <div className="header">
          <div className="left">
            <div className="logo">
              <h2>Brand & Competition report</h2>
            </div>
            <div className="text">
              <div className="text-line">
                <h1>
                  How are your brand performing compared to your competitors?
                </h1>
              </div>
            </div>
            <div className="cta">
              <div className="primary-cta" onClick={toggleForm}>
                Order the report
              </div>
            </div>
          </div>
          <div className="right">
            <div className="text-line">
              <h2>
                Get actionable insights and start to understand how your website and brand is performing organically
                and technically compared to your competitors and industry. 
                <br/><br/>It's 100% free.
              </h2>
            </div>
          </div>
        </div>
        <div className="testomonial">
          <div className="client-text">Words from a client</div>
          <div className="quote">
            Really SOLID report. I can’t belive you don’t charge anything for
            this report.
            <br />
            It does give us alot of insights to actions on.
          </div>
          <div className="person">
            <div className="image">
              <Image src="jonas_karles.jpg" alt="" title="" />
            </div>
            <div className="name">
              <h5>Jonas Karles</h5>
              <h6>COO @ Minna Technologies</h6>
            </div>
          </div>
        </div>
        <div className="featured-wrapper">
          <div className="full-width">
            <h2>Included in report</h2>
            <div className="featured-item">
              <h3>Brand Metrics</h3>
              <h4>
                A meassurement of your brands website rank, rank by country,
                website speed and usage stats based on different data sources
                and compare these to your competitors for a overall holistic
                view with absolute numbers.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Organic Brand Search Share</h3>
              <h4>
                A meassurement of your brands organic brand searches and compare
                it to your competitors and industry to get an pointer on how
                well your brand is performing in organic growth.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Competitors Organic Traffic</h3>
              <h4>
                A calculation of your organic brand search share and get an
                approximately understanding of how many organic searches your
                competitors gets each month on their brand.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Domain Authority Share</h3>
              <h4>
                An meassurement on how well your brands presence and authority
                online is developing and how well your authority competes
                against your competitors. Authority is a good meassurement on
                how well the organic ranking is developing.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Backlink Count Share</h3>
              <h4>
                An meassurement on how much buzz their is around your brand and
                how well your off-page SEO efforts are doing compared to your
                competitors.
              </h4>
            </div>
          </div>
        </div>
        <div className="testomonial">
          <div className="client-text">Words from a client</div>
          <div className="quote">
            Really SOLID report. I can’t belive you don’t charge anything for
            this report.
            <br />
            It does give us alot of insights to actions on.
          </div>
          <div className="person">
            <div className="image">
              <Image src="jonas_karles.jpg" alt="" title="" />
            </div>
            <div className="name">
              <h5>Jonas Karles</h5>
              <h6>COO @ Minna Technologies</h6>
            </div>
          </div>
        </div>
        <div className="featured-wrapper">
          <div className="left">
            <h2>Get access to insights</h2>
            <div className="featured-item">
              <h3>Your brand position</h3>
              <h4>
                Get an understanding of how well your brand is positioned
                online.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Result of branding investments</h3>
              <h4>
                Get an understanding if your branding activities are paying of
                and how big share of voice you have on organic search.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Comparison of branding trends</h3>
              <h4>
                Get an understanding if your branding activities gives you an
                leverage of your competitors.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Technical SEO development</h3>
              <h4>
                Get an understanding if your technical SEO-efforts are
                performing well.
              </h4>
            </div>
            <div className="featured-item">
              <h3>The buzz around the brands</h3>
              <h4>
                Get an understanding how much buzz you get your brand and how
                much buzz your competitors gets.
              </h4>
            </div>
            <div className="featured-item">
              <h3>The authority trends</h3>
              <h4>
                Get an understanding how well your domain authority is
                developing compared to your competitors.
              </h4>
            </div>
          </div>
          <div className="right">
            <h2>Brand & Competitor report</h2>
            <div className="featured-item">
              <h3>Is it really free?</h3>
              <h4>Yes, It's totally free. No strings attached.</h4>
            </div>
            <div className="featured-item">
              <h3>How can it be free?</h3>
              <h4>
                Since I know you'll find it so useful that you'll ask if I can
                automate it for you — And I can but that will cost you a few
                bucks each month for the data resources.
              </h4>
            </div>
            <div className="featured-item">
              <h3>How is the report delivered?</h3>
              <h4>
                The free report is delivered as an PDF. The automated report is
                an Google Data Studio report.
              </h4>
            </div>
            <div className="featured-item">
              <h3>How do I order the report?</h3>
              <h4>
                Click the button below and follow the step to enter the
                information needed to create the report.
              </h4>
            </div>
            <div className="featured-item">
              <h3>Can you also help us with an branding strategy?</h3>
              <h4>
                Yes I can! Order the Branding and Competitor report and we can
                have a chat around it.
              </h4>
            </div>
            <div className="primary-cta" onClick={toggleForm}>
              Continue to order report
            </div>
          </div>
        </div>
        <div className="testomonial">
          <div className="client-text">Words from a client</div>
          <div className="quote">
            Really SOLID report. I can’t belive you don’t charge anything for
            this report.
            <br />
            It does give us alot of insights to actions on.
          </div>
          <div className="person">
            <div className="image">
              <Image src="jonas_karles.jpg" alt="" title="" />
            </div>
            <div className="name">
              <h5>Jonas Karles</h5>
              <h6>COO @ Minna Technologies</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
