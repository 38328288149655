import React from 'react'

export default function CompetitorList(props) {
    const { page, state, stateDispatch, SwitchPage } = props;
    const ClearCompetitor = index => {
        stateDispatch({ type: "clearCompetitor", index: index })
      }
    return (
        <form onSubmit={e => SwitchPage(e, "confirm")}>
          <div
            id={state.competitors[0].competitorName ? "" : "not-active"}
            className={
              page === "competitor-list"
                ? "radio-select show"
                : "radio-select hide"
            }
          >
            <p>
              {state.competitors[0].competitorName
                ? "Competitor"
                : "Click to add competitor"}
            </p>
            <select
              type="radio"
              value={state.competitors[0].competitorName}
              required
            >
              <option value={state.competitors[0].competitorName}>
                {state.competitors[0].competitorName}
              </option>
            </select>
            <div
              className="multi-select"
              onClick={e => SwitchPage(e, "competitor", 0)}
            >
              {state.competitors[0].competitorName}
            </div>
          </div>
          <div
            id={state.competitors[1].competitorName ? "" : "not-active"}
            className={
              page === "competitor-list"
                ? "radio-select show"
                : "radio-select hide"
            }
            onClick={e => {if (state.competitors[1].competitorName.length === 0) SwitchPage(e, "competitor", 1)}}
          >
            <p>
              {state.competitors[1].competitorName
                ? "Competitor"
                : "Click to add competitor"}
            </p>
            <select type="radio" value={state.competitors[1].competitorName}>
              <option value={state.competitors[1].competitorName}>
                {state.competitors[1].competitorName}
              </option>
            </select>
            <div
              className="multi-select"
              onClick={e => SwitchPage(e, "competitor", 1)}
            >
              {state.competitors[1].competitorName}
            </div>
            {state.competitors[1].competitorName.length ? (
              <div className="clear" onClick={() => ClearCompetitor(1)}>
                Clear
              </div>
            ) : null}
          </div>
          <div
            id={state.competitors[2].competitorName ? "" : "not-active"}
            className={
              page === "competitor-list"
                ? "radio-select show"
                : "radio-select hide"
            }
            onClick={e => {if (state.competitors[2].competitorName.length === 0) SwitchPage(e, "competitor", 2)}}
          >
            <p>
              {state.competitors[2].competitorName
                ? "Competitor"
                : "Click to add competitor"}
            </p>
            <select type="radio" value={state.competitors[2].competitorName}>
              <option value={state.competitors[2].competitorName}>
                {state.competitors[2].competitorName}
              </option>
            </select>
            <div
              className="multi-select"
              onClick={e => SwitchPage(e, "competitor", 2)}
            >
              {state.competitors[2].competitorName}
            </div>
            {state.competitors[2].competitorName ? (
              <div className="clear" onClick={() => ClearCompetitor(2)}>
                Clear
              </div>
            ) : null}
          </div>
          <div
            id={state.competitors[3].competitorName ? "" : "not-active"}
            className={
              page === "competitor-list"
                ? "radio-select show"
                : "radio-select hide"
            }
            onClick={e => {if (state.competitors[3].competitorName.length === 0) SwitchPage(e, "competitor", 3)}}
          >
            <p>
              {state.competitors[3].competitorName
                ? "Competitor"
                : "Click to add competitor"}
            </p>
            <select type="radio" value={state.competitors[3].competitorName}>
              <option value={state.competitors[3].competitorName}>
                {state.competitors[3].competitorName}
              </option>
            </select>
            <div
              className={
                state.competitors[3].competitorName
                  ? "multi-select"
                  : "multi-select not-active"
              }
              onClick={e => SwitchPage(e, "competitor", 3)}
            >
              {state.competitors[3].competitorName}
            </div>
            {state.competitors[3].competitorName ? (
              <div className="clear" onClick={() => ClearCompetitor(3)}>
                Clear
              </div>
            ) : null}
          </div>
          <button
            type="submit"
            className={
              page === "competitor-list"
                ? "submit-button show"
                : "submit-button hide"
            }
          >
            Continue
          </button>
          <button
            type="button"
            className={
              page === "competitor-list"
                ? "submit-button show back"
                : "submit-button hide back"
            }
            onClick={e => SwitchPage(e, "brand")}
          >
            Back
          </button>
        </form>
    )
}
