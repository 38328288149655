import React, { useState } from "react"

import { countries } from "./data"

export default function Brand(props) {
    const { page, state, stateDispatch, SwitchPage } = props;
    const [openCountry, setOpenCountry] = useState(false)
    const [searchCountry, setSearchCountry] = useState("")

    const SwitchMultiSelect = (country, type) => {
        stateDispatch({ type: "field", value: country, field: type })
        setOpenCountry(false)
      }


    return (
        <form
          onSubmit={e =>
            SwitchPage(
              e,
              state.competitors[0].competitorName === ""
                ? "competitor"
                : "competitor-list"
            )
          }
        >
          <div
            className={page === "brand" ? "text-field show" : "text-field hide"}
          >
            <p>What's the name of your brand?</p>
            <input
              type="text"
              placeholder="Google"
              value={state.brandName}
              onChange={e =>
                stateDispatch({
                  type: "field",
                  value: e.currentTarget.value,
                  field: "brandName",
                })
              }
              required
            />
          </div>
          <div
            className={
              page === "brand" ? "radio-select show" : "radio-select hide"
            }
          >
            <p>What market do you want to focus on?</p>
            <select type="radio" value={state.brandMarket} required>
              <option value={state.brandMarket}>{state.brandMarket}</option>
            </select>
            <div
              className="multi-select"
              onClick={() => setOpenCountry(true)}
            >
              {state.brandMarket}
            </div>
          </div>
          {openCountry ? (
            <div className="open-multi-select">
              <input
                type="text"
                placeholder="Search for market"
                value={searchCountry}
                onChange={e => setSearchCountry(e.currentTarget.value)}
              />
              <div className="multi-select-list">
                <ul>
                  {searchCountry.length > 0
                    ? countries
                        .filter(country =>
                          new RegExp(".*" + searchCountry + ".*").test(country)
                        )
                        .map(country => (
                          <li
                            key={country}
                            onClick={() => SwitchMultiSelect(country, "brandMarket")}
                          >
                            {country}
                          </li>
                        ))
                    : countries.map(country => (
                        <li
                          key={country}
                          onClick={() => SwitchMultiSelect(country, "brandMarket")}
                        >
                          {country}
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          ) : null}
          <div
            className={page === "brand" ? "text-field show" : "text-field hide"}
          >
            <p>What's your URL for this specific market?</p>
            <input
              type="url"
              placeholder="www.google.com"
              value={state.brandUrl}
              onChange={e =>
                stateDispatch({
                  type: "field",
                  value: e.currentTarget.value,
                  field: "brandUrl",
                })
              }
              required
            />
            <p className="require">You must include http:// or https://</p>
          </div>
          <div
            className={page === "brand" ? "text-field show" : "text-field hide"}
          >
            <p>How do you search on Google for your brand?</p>
            <input
              type="text"
              placeholder="google"
              value={state.brandSearchTerm}
              onChange={e =>
                stateDispatch({
                  type: "field",
                  value: e.currentTarget.value,
                  field: "brandSearchTerm",
                })
              }
              required
            />
          </div>
          <button
            className={
              page === "brand" ? "submit-button show" : "submit-button hide"
            }
            type="submit"
          >
            Next
          </button>
          <button
            type="button"
            className={
              page === "brand"
                ? "submit-button show back"
                : "submit-button hide back"
            }
            onClick={e => SwitchPage(e, "start")}
          >
            Back
          </button>
        </form>
    )
}
